import React from "react"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"

const AboutPage = function () {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "developer.png" }) {
        childImageSharp {
          fixed(width: 380, height: 330) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Layout header={true} footer={true}>
      <SEO title="Home" />
      <section className="section">
      <div className="container">
          <div className="columns">
            <div className="column content is-two-thirds">
              <h1>About Us</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                molestie facilisis massa sed efficitur. Donec viverra tortor sed
                fermentum dictum. Quisque suscipit ante at nulla aliquet, et
                consectetur augue pharetra. Praesent bibendum eget magna ut
                dapibus. Aenean at euismod magna. Mauris eleifend ligula
                iaculis, imperdiet nulla vel, semper tortor. Sed hendrerit arcu
                eget ornare pretium. Donec efficitur purus orci. Aliquam vel
                sagittis purus. Nullam eu augue at felis sodales viverra sodales
                eu velit. Duis eget sem quis turpis semper lacinia quis eget
                urna. Fusce efficitur nibh a felis sollicitudin vulputate. Sed
                ut tortor eget purus lacinia eleifend id ac arcu. Suspendisse
                mattis tempus scelerisque.
              </p>
              <p>
                Integer condimentum et tellus non efficitur. Class aptent taciti
                sociosqu ad litora torquent per conubia nostra, per inceptos
                himenaeos. Curabitur sagittis urna ante, at dapibus massa mattis
                eget. Duis at convallis magna. In vitae fermentum mauris. Aenean
                id interdum libero. Curabitur viverra vestibulum felis. Vivamus
                nunc mauris, facilisis ut venenatis eu, vestibulum vel felis.
                Fusce ut iaculis justo, eu posuere dolor.
              </p>
            </div>
            <div className="column is-hidden-mobile">
              <Img fixed={data.file.childImageSharp.fixed} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage
